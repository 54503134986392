.FinalUrl {
	padding: 10px;
}
.FinalUrl .Inputs {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-top: 20px;
	margin-bottom: -40px;
}
.FinalUrl .Buttons {
	display: flex;
	justify-content: flex-end;
}
.FinalUrl p {
	padding-bottom: 20px;
}

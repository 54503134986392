.AgentMove {
	width: 40vw;
}
.AgentMove .Selects {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.AgentMove .Selects .Instruction {
	margin-bottom: 10px;
}
.AgentMove .Selects .Select {
	margin-bottom: 10px;
}
.AgentMove .AgentMoveFooter {
	display: flex;
	justify-content: flex-end;
}

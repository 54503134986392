.ShowTargetingControl {
	display: grid;
	grid-template-columns: min-content min-content;
	white-space: nowrap;
	grid-column-gap: 5px;
	font-size: 12px;
}
.ShowTargetingControl input {
	margin-top: auto;
	height: 100%;
}

.ProxyMove.Wide {
	width: 90vw;
	max-height: 95vh;
}
.ProxyMove.Narrow {
	width: 40vw;
}
.ProxyMove .Header {
	display: flex;
	align-items: center;
}
.ProxyMove .ProxyGroupSelector {
	margin-bottom: 10px;
}
.ProxyMove .MoveInvestment {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	margin-top: 20px;
}
.ProxyMove .MoveInvestment .Typo {
	margin-right: 10px;
}
.ProxyMove .MuiToggleButton-root.Mui-selected	 {
	background-color: rgba(0, 178, 227, 1);
	color:white;
}
.ProxyMove .Tables {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
}
.ProxyMove .Current {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding:20px;
}
.ProxyMove .Next {
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.ProxyMove .Selected {
	background-color: rgba(0, 178, 227, 0.2);
	border-radius: 10px;
}
.ProxyMove .CardFooter {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

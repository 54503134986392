.MenuComponent.menu_opened ul li a p {
	display: block;
}
.MenuComponent.menu_closed ul li a p {
	display: none;
}
@media screen and (min-width: 992px) {
	.MenuComponent.menu_opened .sidebar .sidebar-wrapper,
	.MenuComponent.menu_opened .sidebar .logo {
		width: 260px;
	}
	.MenuComponent.menu_closed .sidebar .sidebar-wrapper,
	.MenuComponent.menu_closed .sidebar .logo {
		width: 100px;
	}

	.MenuComponent.menu_opened .MenuComponentChildren {
		margin-left: 260px;
	}
	.MenuComponent.menu_closed .MenuComponentChildren {
		margin-left: 100px;
	}
	.MenuComponent.menu_closed .sidebar .button {
		display: none;
	}
	.MenuComponent.menu_closed .nav li {
		width: 100px;
	}
	.MenuComponent.menu_closed .nav li a {
		height: 52px;
	}
}
@media screen and (max-width: 991px) {
	.MenuComponent.menu_opened .MenuComponentChildren {
		margin-left: 0px;
	}
	.MenuComponent.menu_closed .MenuComponentChildren {
		margin-left: 0px;
	}
	.MenuComponent.menu_opened .sidebar {
		background-color: white;
		width: 100%;
	}
	.MenuComponent.menu_opened .sidebar-wrapper {
		background-color: white;
		width: 100%;
		height: calc(100vh - 206px) !important;
	}
	.MenuComponent.menu_opened .sidebar-wrapper {
		width: 100%;
	}
	.MenuComponent.menu_opened .showOnlyWhenMenuOpenedOnMobile {
		animation-name: fadeIn;
		animation-duration: 1500ms;
		display: block;
		position: absolute;
		top: 0px;
		right: 0px;
		margin-top: 20px;
		margin-right: 20px;
		text-align: center;
		z-index: 9999;
	}
}
.MainViewContent > *:first-child {
	margin-top: 0px;
	margin-left: 10px;
}
.MainViewContent {
	padding: 10px;
	max-width: 99%; /*This fix rapid fire from chrome scroll bar*/
	position: relative; /* Used by an absolutely positioned child ("planner not active" overlay) */
}
.menu_closed ul.nav > li > a {
	display: grid;
}
.menu_closed ul.nav > li > a > i.material-icons {
	align-self: center;
	justify-self: center;
	margin: 0px;
}
.menu_closed .sidebar .sidebar-wrapper {
	height: calc(100vh - 170px) !important;
}
.menu_closed ul.nav > li > a {
	display: grid;
}
.menu_closed ul.nav > li > a > i.material-icons {
	align-self: center;
	justify-self: center;
	margin: 0px;
}

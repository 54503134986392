.AdCopiesRow .Name {
	text-align: center;
	font-size: 10px;
	align-self: center;
}
.AdCopiesRow .AdCopies {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: 525px;
	grid-column-gap: 10px;
	overflow-x: scroll;
}

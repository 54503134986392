.FriendViewViewerHeader {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.FriendViewViewerHeader ul {

	align-self: center;
	justify-self: right;
}

.ViewerComponent .Hint {
	flex-direction: column;
}

@media screen and (min-width: 992px) {
	.ViewerComponent .HeaderViewer .ViewChanger {
		align-self: center;
	}
}

@media screen and (max-width: 991px) {
	.ViewerComponent {
		font-size: 75%;
	}
}

.HeaderViewer h4 {
	flex-grow: 3;
	font-size: 32px;
}

.HeaderViewer .Status-modal-wrapper {
	order: 2;
	margin-right: 1em;
}

.HeaderViewer .view-changer {
	order: 3;
	width: 11%;
}

.HeaderViewer .GoBack::after {
	top: 15px;
}
.MenuToggleBase {
	cursor: pointer;
}
.MenuToggle {
	position: relative;
	z-index: 102;
	display: grid;
	height: 50px;
	box-shadow: -5px -1px 20px 6px rgba(0, 0, 0, 0.12);
	cursor: pointer;
}
.MenuToggle span {
	align-self: center;
	justify-self: center;
}

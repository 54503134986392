.WeightsConfiguration .WeightsConfigurationFooter {
	display: flex;
	justify-content: flex-end;
}
.WeightsConfiguration .Content {
	display: flex;
	align-items: center;
}
.NameContainer {
	display: flex;
	align-items: center;
}
.Name {
	max-width: 220px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ProxiesAmountsDisplay {
	width: 49%;
	overflow: auto!important;
	max-height: 200px;
}
.ProxiesAmountsDisplay .Table {
	max-height: 150px;
}
.ProxiesAmountsDisplay .TableHeader .HeaderCell {
	background-color: #00B2E3;
	color:#fff;
	font-weight: bold;
	font-size: 1.2rem;
	text-transform: uppercase;
}

.CopyAndReplaceGeo .ExplanationText {
	font-size: 20px;
	margin: 5px;
}
.CopyAndReplaceGeo .GeoBidsParams .NotSelected {
	opacity: 0.5;
}
.CopyAndReplaceGeo .GeoBidsParams .Selected {
	border: 1px dashed black;
}
.CopyAndReplaceGeo .ExplanationText {
	margin-top: 20px;
}
.CopyAndReplaceGeo .ReplacedOptions .Cancel {
	background-color: transparent;
	color: #ff5858;
	border-color: #e4e4e4;
}
.CopyAndReplaceGeo .ReplacedSectionContainer {
	margin-top: 10px;
}

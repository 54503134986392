.Category {
	display: grid;
	text-align: center;
	border-left: solid 1px grey;
	padding: 40px;
}
.Category:first-child {
	border-left: none;
}
@media screen and (max-width: 990px) {
	.Category {
		border: none;
	}
}
.card {
	display: inline-block;
	position: relative;
	width: 100%;
	margin: 0px;
	box-shadow: none;
	color: #5d5d5d;
	background: #fff;
}
.card .card-header-custom {
	padding: 5px 10px;
	background-color: #fff;
}
.card .row-space .header {
	padding: 15px 20px 0;
}

.card .title {
	font: normal normal normal 32px/43px Roboto, sans-serif;
	margin-top: 0;
	margin-bottom: 5px;
}
.card .card-content {
	padding: 5px 10px;
	background-color: #fff;
}

.header-with-back-button {
	margin-left: 40px !important;
}
.Languages {
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}
.Languages .Switch {
	display: flex;
	justify-content: space-around;
	margin-top: 20px;
}
.Languages .Switch .Text {
	color: #474747;
	font-weight: 550;
	font-size: 14px;
}
.Languages .Tip {
	display: flex;
	font-style: italic;
	font-size: 0.9rem;
}
.Languages .material-icons {
	margin-top: 2px;
	margin-right: 5px;
	color: #ff6700;
}

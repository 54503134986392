.highlightedRow {
	display: grid;
	grid-template-columns: min-content max-content;
	align-items: center;
	grid-column-gap: 5px;
}
.MissedOppDataTable tbody td {
	font-size: 12px;
}
.MissedOppDataTable thead {
	font-size: 13px;
}
.MissedOppDataTable th {
	font-size: 1.5em !important;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 300;
	line-height: 1.5em;
}
.MissedOppDataTable .fixedSizedColumn {
	width: 115px;
}
.MissedOpportunities .pagination.react-bootstrap-table-page-btns-ul {
	display: none;
}
.EmptyMissedOpportunities {
	text-align: center;
	margin-top: 25%;
}

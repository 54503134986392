.SimpleKeyValueTable {
	display: grid;
	grid-template-columns: max-content 1fr;
	grid-column-gap: 6px;
}
.SimpleKeyValueTable .key {
	font-weight: bold;
}
.SimpleKeyValueTable.spillRight .key {
	margin-left: 16px;
}
.SimpleKeyValueTable.spillRight .key:first-child {
	margin-left: 0px;
}
.SimpleKeyValueTable .value {
}
.SimpleKeyValueTable.spillRight {
	grid-template-columns: initial;
	grid-auto-flow: column;
	justify-content: left;
}

.LoadingPieChart {
	display: grid;
	place-content: center;
	width: 100%;
	height: 100%;
}
.OverviewRapport .ByDeviceHead {
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: inherit;
	margin-top: 10px;
	margin-bottom: -10px;
}
.OverviewRapport .ByDeviceHead .MuiOutlinedInput-input {
	padding: 6px;
	padding-right: 30px;
	font-family: "Roboto", inherit;
	font-weight: 300;
}


.ShapePointsList .PointsList .Point {
	display: grid;
	grid-template-columns: 1fr 3fr 3fr;
	align-items: center;
	grid-column-gap: 5px;
	cursor: pointer;
}
.ShapePointsList .PointsList .Point.Selected {
	background-color: #ddd;
}
.ShapePointsList .PointsList .Point .RemovePointButton {
	background-color: red;
}
.ShapePointsList .PointsList .PolygonSeparator {
	height: 15px;
	cursor: pointer;
}
.ShapePointsList .PointsList .PolygonSeparator:hover {
	background-color: aqua;
}
.ShapePointsList .PolygonHeader {
	cursor: pointer;
}
.ShapePointsList .PolygonHeader:hover {
	background-color: red;
}

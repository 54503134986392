.ColumnManager {
	display: grid;
	grid-template-rows: 50px 1fr;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 15px;
	grid-row-gap: 10px;
}
.ColumnManager .ColumnsExchangeContext {
	grid-column-start: 1;
	grid-column-end: 3;
}
.ColumnManager .ContainerTitle {
	text-align: center;
}

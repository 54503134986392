
.NoData {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 200px;
}
.TwoLineGraph {
	display: grid;
	padding: 10px;
}
.TwoLineGraph .Header {
	display: grid;
	grid-template-columns: max-content max-content;
	align-items: center;
	justify-content: space-between;
}
.TwoLineGraph .Header .Title {
	margin-left: 15px;
}
.TwoLineGraph
	.Header
	.ToggleButton
	.CompareToggle
	.bx--toggle__label
	.bx--toggle__text--right {
	margin-left: 0;
}
.TwoLineGraph
	.Header
	.ToggleButton
	.CompareToggle
	.bx--toggle__label
	.bx--toggle__appearance::before {
	top: -2px;
	width: 84%;
	margin-left: 2px;
}
.TwoLineGraph
	.Header
	.ToggleButton
	.CompareToggle
	.bx--toggle__label
	.bx--toggle__appearance::after {
	top: -9px;
	width: 19px;
	height: 19px;
}
.TwoLineGraph .Header .ToggleButton .CompareToggle.Toggled .bx--toggle__label {
	color: #00b2e3;
}
.TwoLineGraph
	.Header
	.ToggleButton
	.CompareToggle.Toggled
	.bx--toggle__label
	.bx--toggle__appearance::before {
	background-color: #00b2e3;
}
.TwoLineGraph
	.Header
	.ToggleButton
	.CompareToggle.Toggled
	.bx--toggle__label
	.bx--toggle__appearance::after {
	background-color: #00b2e3;
	border-color: #00b2e3;
}
.ToggleButton
	.bx--toggle:focus
	+ .bx--toggle__label
	.bx--toggle__appearance::after {
	box-shadow: 0 0 0 3px #b7e0ff;
}
.TwoLineGraph .Header .GraphControls {
	display: grid;
	grid-template-columns: 1fr min-content;
	justify-items: right;
	align-items: center;
}
.TwoLineGraph .Header .GraphControls .ToggleButton {
	margin-right: 30px;
}
.TwoLineGraph .Header .GraphControls .statPanelCheckbox {
	display: grid;
	justify-content: right;
	grid-template-columns: min-content min-content;
	grid-column-gap: 35px;
	margin-right: 50px;
	justify-self: right;
	white-space: nowrap;
}

@media screen and (max-width: 950px) {
	.TwoLineGraph .Header {
		grid-template-columns: 1fr;
	}
}

@media screen and (max-width: 700px) {
	.TwoLineGraph .Header .GraphControls {
		grid-template-columns: 1fr;
		grid-template-rows: min-content min-content;
	}
}
@media screen and (max-width: 500px) {
	.TwoLineGraph .Header .GraphControls .statPanelCheckbox {
		grid-template-columns: 1fr;
	}
}

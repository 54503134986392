.SimpleCard {
	display: grid;
	grid-template-columns: 40% 60%;
	border: 1px solid #ddd;
	min-width: 255px;
}
.SimpleCard .SimpleCardIcon {
	padding: 8px;
	width: 100%;
	height: 100%;
	border: 1px solid #00b2e3;
	background-color: #00b2e3;
	text-align: center;
}
.SimpleCard .SimpleCardIcon img {
	max-width: 95px;
}

.SimpleCard .SimpleCardText {
	font-weight: 400;
	color: white;
	font-size: 30px;
	white-space: nowrap;
	font-size: 1rem;
	line-height: 1.5;
}
.SimpleCard .SimpleCardNumberText {
	white-space: nowrap;
	font-weight: 900;
	font-size: 3.5em;
	color: #00b2e3;
	align-self: center;
	justify-self: center;
}

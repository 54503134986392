.MenuHeader {
	position: relative;
	z-index: 100;
	display: grid;
	grid-gap: 10px;
	padding: 10px 10px 10px 10px;
	grid-template-columns: 98% 1fr;
	background-color: white;
}

.showOnlyWhenMenuClosedOnMobile {
	display: none;
}
@media screen and (max-width: 991px) {
	.showOnlyWhenMenuClosedOnMobile {
		justify-self: center;
		align-self: center;
		display: block;
	}
}
.AdGroups {
	padding: 20px;
}
.AdGroups .AdGroupsCard {
	margin-top: 30px;
	padding: 20px;
}
.AdGroups .AdGroupsCard .Audiences .Table .CenterText {
	text-align: center;
	white-space: nowrap;
}
.AdGroups .AdGroupsCard .Icon {
	margin-right: 5px;
}
.AdGroups .AdGroupsCard .Create {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 20px;
}
.AdGroups .AdGroupsCard .PanelHeader div {
	display: flex;
	align-items: space;
	justify-content: space-between;
}
.AdGroups .AdGroupsCard .PanelHeader .EditAdGroupButtons button {
	margin: 0px 0px 0px 10px;
}
.KeywordsInputModal {
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.KeywordsInputModal .KeywordsInputContainer {
	min-width: 600px;
}

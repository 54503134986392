.statPanel {
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: 10% 80%;
	padding: 10px;
}
.statPanelHeader {
	display: grid;
	padding: 10px;
	grid-template-columns: auto auto;
}
.statPanelCheckbox {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}
.statPanelCheckboxItem {
	display: grid;
	justify-self: center;
	grid-template-columns: auto auto;
}
.statPanelCheckboxItem * {
	align-self: center;
}
.DualGraph {
	width: 100%;
	height: 100%;
}

.AgentWeightsConfiguration .AgentWeightsConfigurationFooter {
	display: flex;
	justify-content: flex-end;
}
.AgentWeightsConfiguration .Content {
	display: flex;
	align-items: center;
}
.AgentWeightsConfiguration .Content .WeightInputs {
	max-height: 500px;
	overflow: auto;
}

.MapDisplayOnly {
	min-height: 400px;
	display: grid;
	grid-template-rows: 1fr;
	background-color: #eee;
}
.MapDisplayOnly.hasMapMatcher {
	border: 1px solid lightgrey;
	border-top: 4px solid #00addb;
}
.MapDisplayOnly .MapMatcher {
	font-weight: 700;
	text-align: center;
	width: 100%;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	padding: 4px 0px;
	border-bottom: 1px solid #ddd;
}

.Preview {
	display: flex;
	flex-direction: column;
	height: auto;
}
.Preview .Hidden {
	display: none;
}
.Preview .Header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 1%;
	margin-bottom: 10px;
}
.Preview .Display {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.Preview .Display .PreviewPlusPagination {
	display: flex;
	justify-content: space-evenly;
	width: 800px;
}
.Preview .Display .Variations {
	min-width: 600px;
}
.Preview .Display .PreviewPlusPagination .NavigationButton span {
	font-size: 100px;
}
.Preview .Display .Pagination {
	margin-top: 20px;
}
.Preview .Display .Header {
	padding: 20px;
	min-height: 120px;
	background-color: #f8f8f8;
}
.Preview .Display .Variations .Content {
	height: 370px;
	overflow: auto;
}
.Preview .Footer {
	display: flex;
	justify-content: space-between;
	padding: 10px 1% 10px 1.5%;
}
.Preview .Footer .Icon {
	color: #ffc966;
	transform: translateY(5px);
	margin-right: 5px;
}
.Preview .Footer .Tip {
	font-size: 0.9rem;
	font-style: italic;
}
.Preview .Footer .Buttons .Actions button {
	margin-left: 10px;
}
.Preview .Footer .Buttons {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.Preview .Footer .Buttons .Error {
	font-weight: bold;
	padding-top: 5px;
}
.content {
	padding-top: 70px;
	min-height: 100vh; /* will cover the 100% of viewport */
	overflow: hidden;
	display: block;
	position: relative;
	padding-bottom: 100px; /* height of your footer */
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
	background-color: #00b2e3;
	border-color: #00b2e3;
	box-shadow: 0 4px 5px 0 rgba(31, 49, 64, 0.14),
		0 1px 10px 0 rgba(39, 140, 176, 0.12),
		0 2px 4px -1px rgba(39, 126, 176, 0.2);
}

.NoKeywords {
	padding-top: 50px;
	display: flex;
	flex-direction: column;
}
.NoKeywordsText {
	font-size: 26px;
	text-align: center;
	margin-bottom: 5px;
}

.FilterInputWithButtons {
	padding: 20px;
	min-height: 300px;
}
.FilterInputWithButtons .ProxyGroupsList {
	padding: 20px;
	max-height: 300px;
	overflow: auto;
}

/* .FilterInputWithButtons__Button-Active{
	border: 1px solid rgba(0, 0, 0, 1)!important;
} */

.EditableMuiTextContainer {
	display: flex;
	width: 100%;
}
.EditableMuiTextContainer .EditableMuiText {
	width: unset;
}
.ProxyGroupExpansionPanel .ExpansionPanel .Channel {
	font-weight: bold;
	padding-right: 20px;
	flex: 0 0 auto;
}
.ProxyGroupExpansionPanel .ExpansionPanel .Channel .ChannelText {
	font-weight: bold;
}

.nav > li > a {
	background-color: #fcfcfc;
	border: 1px solid #eee;
}
.nav > li > a:hover {
	-webkit-transition: all 300ms ease-in;
	-o-transition: all 300ms ease-in;
	transition: all 300ms ease-in;
	background-color: #999;
	border: 1px solid lightgrey;
}
.nav > li > a:focus i,
.nav > li > a:hover i {
	-webkit-transition: all 300ms ease-in;
	-o-transition: all 300ms ease-in;
	transition: all 300ms ease-in;
	color: #333;
}
.nav li.active ul li a {
	background-color: #fcfcfc;
	border: 1px solid #eee;
}

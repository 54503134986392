.NameAndLanguages {
	padding: 20px;
	margin: 0 40px 30px 40px;
}
.NameAndLanguages .TextWithIcon {
	display: flex;
	align-items: center;
}
.NameAndLanguages .Content .Instructions {
	margin-bottom: 15px;
	margin-top: 20px;
	display: flex;
	align-items: center;
}
.NameAndLanguages .TextWithIcon .Icon {
	margin-right: 5px;
}
.NameAndLanguages .Content {
	padding: 20px;
}

.NameAndLanguages .Info .Icon {
	margin-right: 2px;
	transform: translateY(6px);
	color: gray;
}

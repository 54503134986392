.IdentityVariantsTester {
}
.IdentityVariantsTester .AdCopy {
	padding: 20px;
	margin: 20px;
}
.IdentityVariantsTester .Failed .FailedAdCopy {
	border: 1px solid #e53935;
	background-color: rgba(255, 0, 0, 0.01);
}
.IdentityVariantsTester .Failed :hover {
	cursor: pointer;
}
.IdentityVariantsTesterToolTip {
	display: flex;
	flex-direction: column;
}
.IdentityVariantsTesterToolTip .Error {
	font-size: 0.9rem;
	font-weight: bold;
}
.IdentityVariantsTesterToolTip .Tip {
	font-size: 0.85rem;
	font-style: italic;
}
.IdentityVariantsTesterToolTip .Icon {
	transform: translateY(5px);
}

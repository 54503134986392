.ProxyGroupReport {
	min-height: 1000px;
}
.ProxyGroupReport .Navigation {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: max-content;
	grid-column-gap: 10px;
	align-items: center;
}
.ProxyGroupReport .ProxyGroupSummaryChart {
	height: 200px;
}
.ProxyGroupReport .AgentAttemptsChart {
	height: 200px;
}
.ProxyGroupReport .AgentSpentChart {
	height: 200px;
}

/* Chart tooltip */
.CustomTooltip {
	padding: 10px;
	background-color: #ffffff;
	border: 1px solid rgb(204, 204, 204);
	white-space: nowrap;
}
.CustomTooltip p {
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	margin-bottom: 7px;
}
.CustomTooltip p:last-child {
	margin-bottom: 0px;
}

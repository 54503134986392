.RadiusManager .Inputs {
	font-size: 13px;
}
.RadiusManager .Actions {
	text-align: right;
}
.RadiusManager .RadiusInfo .RadiusDeleteButton {
	background-color: #ccc;
	padding: 4px;
}
.RadiusManager .RadiusInfo .RadiusDeleteButton:hover {
	background-color: red;
}

.BiddingStrategy {
	padding: 10px;
}
.BiddingStrategy .Select {
	display: flex;
	align-items: center;
	padding-top: 40px;
	justify-content: space-around;
}
.BiddingStrategy .Buttons {
	display: flex;
	justify-content: flex-end;
	margin-bottom: -20px;
}
.BiddingStrategy .Select input {
	width: 100%;
}

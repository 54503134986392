.Keywords {
	padding: 20px;
}

.Keywords .Selects {
	display: flex;
	padding-right: 1%;
}

.Keywords .Selects .Languages {
	margin-right: 60px;
}
.Keywords .Positive {
	margin-right: 40px;
}
.Keywords .Display .Inputs {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: auto;
	padding-top: 30px;
}
.Keywords .ErrorSnackBar {
	background-color: #e53935;
	display: flex;
	align-items: center;
}
.Keywords .ErrorSnackBar .Error {
	display: flex;
	align-items: center;
}
.Keywords .ErrorSnackBar .Error .Icon {
	margin-right: 10px;
}
.Keywords .ErrorSnackBar .Error .Message {
	font-weight: bold;
}

.InventoryTester {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 50px 1fr;
	margin-top: 45px;
}
.InventoryTester .SingleAdCopy {
	margin-left: auto;
	margin-right: auto;
}
.InventoryTester .Invalids .AdCopyViewer {
	border-color: red;
	border-style: solid;
	border-width: 2px;
}
.InventoryTester h4 {
	text-align: center;
}
.AdCopyPatternList .SingleAdCopy-Selected .AdCopyViewer {
	background-color: yellow;
}
.SingleAdCopy {
	display: grid;
	grid-template-columns: max-content max-content;
	grid-column-gap: 16px;
	align-items: center;
}
.SingleAdCopy .AdCopyViewer,
.SingleAdCopy-Selected {
	width: 600px;
	cursor: pointer;
}
.Valids,
.Invalids{
	display: grid;
	justify-content: center;
}

.container {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.card {
	padding: 10px;
}

.KeyValueTable {
	display: grid;
	grid-template-columns: max-content max-content;
	grid-row-gap: 10px;
	grid-column-gap: 20px;
	font-weight: bold;
}
.KeyValueTable .value {
	background-color: #eee;
	border-radius: 8px;
	padding: 0px 20px;
	border: 1px inset rgba(255, 255, 255, 0.2);
	font-weight: normal;
}
.KeyValueTable .value.edited {
	background-color: yellow;
}
.KeyValueTableWithTitle {
	border: 1px solid #ddd;
	padding: 20px;
	width: min-content;
	border-radius: 6px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}
.KeyValueTableWithTitle h4 {
	margin-bottom: 10px;
}

.SimpleRSAAdCopy {
  font-size: 14px;
  line-height: 1.2;
  text-align: left;
  display: grid;
  grid-row-gap: 3px;
}
.SimpleRSAAdCopy .Title {
  font-size: 16px;
  color: #0032d9;
}
.SimpleRSAAdCopy .Url {
  color: #189002;
}
.SimpleRSAAdCopy .Description {
  color: #707070;
}

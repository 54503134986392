.ProxiesPanel {
	width: 100%;
}
.ProxiesPanel .ProxyExpansionPanel {
	background-color: whitesmoke;
}
.ProxiesPanel .ProxyExpansionPanelSummary .MuiExpansionPanelSummary-content {
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.ProxiesPanel .ProxyDetails {
	display: flex;
	flex-direction: column;
}
.ProxyMoveModal {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}
.PieChart {
	display: flex;
	align-items: center;
	color: gray;
	position: absolute;
	right: 150px;
	justify-content: space-around;
	width: 105px;
	pointer-events: none;
}

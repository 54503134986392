.ArrayWithEditAndDelete {
	height: 200px;
	overflow-y: auto;
}
.ArrayWithEditAndDelete .Table {
	width: auto;
	overflow: scroll;
}
.ArrayWithEditAndDelete .Table .Error {
	color: rgb(229, 57, 53);
	display: flex;
	align-items: center;
}
.ArrayWithEditAndDelete .Table .Error span {
	margin-right: 5px;
}
.ArrayWithEditAndDelete .Table .Edit {
	max-width: 50px;
	transition-duration: 0.24s;
	transition-property: color, background-color;
}
.ArrayWithEditAndDelete .Table .Edit:hover {
	cursor: pointer;
	background-color: rgba(0, 178, 227, 0.8);
	color: white;
	border-radius: 3px;
}
.ArrayWithEditAndDelete .Table .Delete {
	max-width: 50px;
	transition-duration: 0.24s;
	transition-property: background-color, color;
}
.ArrayWithEditAndDelete .Table .Delete:hover {
	cursor: pointer;
	color: white;
	background-color: rgba(229, 57, 53, 0.9);
	border-radius: 3px;
}

.SingleCampaignSelection {
	display: flex;
	align-items: center;
	justify-content: center;
}
.SingleCampaignSelection .DoesntExist {
	color: red;
	font-weight: normal;
	font-size: 12px;
	margin-left: 5px;
}

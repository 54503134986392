.AddGuidelineList .Interface {
	display: grid;
	grid-template-columns: 1fr 5fr;
	height: 83vh;
}
.AddGuidelineList .Interface .Tools {
	display: flex;
	flex-direction: column;
	height: 85vh;
	overflow: scroll;
}
.AddGuidelineList .Interface .Guidelines {
	overflow: scroll;
}
.AddGuidelineList .Interface .BudgetName {
	width: 150px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.AddGuidelineList .Interface .UserEmail {
	width: 150px;
	overflow: hidden;
	white-space: nowrap;
	display: block;
	text-overflow: ellipsis;
}
.AddGuidelineList .Header {
	display: flex;
	align-items: center;
	padding-left: 1%;
	padding-right: 1%;
	justify-content: space-between;
}
.AddGuidelineList .Header button {
	margin-top: 13px;
	margin-left: 20px;
}
.AddGuidelineList .Header h3 {
	padding: 10px;
}
.MuiTooltip-tooltip.TooltipFont {
	font-size: 15px;
	max-width: 100%;
}

.Tester {
	display: flex;
	justify-content: flex-start;
	min-width: 100px;
	padding-left: 20px;
	padding-top: 10px;
}
.Tester p {
	margin: 5px 20px 0px 0px;
}
.Tester .Passing {
	color: white;
	margin-right: 10px;
}
.Tester .Icon {
	margin-right: 5px;
	margin-left: -5px;
}

.account-modal-wrapper {
	position: relative;
	z-index: 101;
	padding: 10px 15px;
	text-align: center;
	cursor: pointer;
	max-width: 256px;
}
.account-modal-wrapper-toggled {
	position: relative;
	z-index: 101;
	padding: 10px 15px;
	text-align: center;
	cursor: pointer;
	max-width: 100px;
}
.account-modal-wrapper .bx--modal-content {
	overflow-y: visible;
}
.account-modal-wrapper .react-bootstrap-table {
	overflow-x: hidden;
}

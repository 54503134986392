.BoostConfig .BoostDisabled  {
	position: relative;
	min-height: 340px;
	min-width: 540px;
	padding: 20px;
	background-size:cover;
	display: flex;
	align-items: center;
	justify-content: space-around;
	color: #00B2E3;
	z-index: 0;
	font-weight: bold;
}
.BoostConfig .BoostDisabled:after {
	content: '';
	background: inherit;
	position: absolute;
	top:0;
	left: -10px;
	filter: blur(4px);
	width: 103%;
	height: 103%;
	z-index: -1;
}
.BoostConfig .BoostDisabled .InsideBoostDisabled p {
	font-size: 1.1rem;
}

.ProxyGroup {
	display: grid;
	grid-template-columns: 15px 1fr minmax(300px, 500px);
	grid-column-gap: 5px;
	grid-template-rows: auto auto 50px auto;
}
.ProxyGroup.WithoutGraph {
	grid-template-columns: 1fr;
}
.ProxyGroup .Spinner{
	display: grid;
	align-content: center;
	justify-content: center;
	grid-column: 1 / 3;
}
.ProxyGroup .FatBorder {
	height: 8px;
	width: 100%;
	grid-column: 1 / 4;
	margin-bottom: 5px;
	align-self: start;
}

.ProxyGroup .Header {
	display: grid;
	grid-column: 2 / 3;
	margin-bottom: 15px;
}
.ProxyGroup .Header .Title {
	display: grid;
	grid-auto-flow: column;
	float: left;
	padding-right: 50px;
}
.ProxyGroup .Header .Title .Name {
	color: #212121;
	max-width: unset !important;
}
.ProxyGroup .Header .Title .Channel {
	font-weight: bold;
	padding-right: 20px;
}
.ProxyGroup .Header .Title .Options {
	margin-top: 32px;
	margin-left: 10px;
	display: grid;
	grid-auto-flow: column;
	grid-column-gap: 5px;
}
.ProxyGroup .Header .Title .Options .OptionIcon {
	color: #b1b1b1;
	opacity: 0.4;
	cursor: pointer;
	transition: opacity;
	transition-duration: 100ms;
}
.ProxyGroup .Header .Title:hover .Options .OptionIcon {
	opacity: 1;
}
.ProxyGroup .Header .Title:hover .Options .OptionIcon:hover {
	color: #898989;
}

.ProxyGroup .Planning {
	display: grid;
	grid-template-columns: min-content 1fr min-content;
	grid-column-gap: 5px;
	align-items: center;
	border-left: 1px solid #dadada;
	border-right: 1px solid #dadada;
	padding-bottom: 35px;
	grid-row: 2 / 3;
}
.ProxyGroup .Planning .SettingsIcon {
	grid-column: 1 / 4;
	justify-self: end;
	color: #b1b1b1;
	margin-top: 3px;
	margin-right: 2px;
	cursor: pointer;
}
.ProxyGroup .Planning .SettingsIcon:hover {
	color: #898989;
}
.ProxyGroup .Planning .ArrowButton {
	color: #b1b1b1;
}
.ProxyGroup .Planning .ArrowButton.left {
	margin-left: 10px;
}
.ProxyGroup .Planning .ArrowButton.right {
	margin-right: 10px;
}
.ProxyGroup .RightChart {
	grid-row: 2 / 4;
	grid-column: 3 / 4;
	border: 1px solid #dadada;
	border-top: none;
	height: 100%;
}
.ProxyGroup .RightChart .ProxyGroupSummaryChart {
	height: 216px;
}
.ProxyGroup .PreciseGuidelineSetup {
	grid-row: 3 / 5;
}
.ProxyGroup .Alert {
	margin: 0px -6px 0px 0px;
	grid-column: 1 / 2;
	grid-row: 2 / 5;
	z-index: 1;
}

.FriendsListDataTable tbody {
	font-size: 12px;
}
.FriendsListDataTable {
	width: 100%;
}
.FriendsListDataTable .order {
	display: none;
}
.ActiveCampaign {
	border-left: 3px solid green;
}
.PausedCampaign {
	border-left: 3px solid #ff9800;
}
.SimpleStatusCell {
	padding-left: 10px;
}

.EditableMuiText {
	width: 100%;
	display: flex;
	text-align: center;
}
.EditableMuiText .TextWrap {
	display: block;
	max-width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.EditableMuiText:hover .Icon {
	opacity : 1;
	margin-top: 4px;
}
.EditableMuiText .Icon {
	margin-left: 10px;
	opacity: 0;
	transition: opacity 50ms ease-in-out;
}
.EditableMuiText .DoneIcon:hover {
	transform: scale(1.3);
}


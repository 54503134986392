.CreateProxyGroup {
	min-width: 30vw;
}
.CreateProxyGroup .Content {
	padding: 30px;
}
.CreateProxyGroup .FirstRow {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
}
.CreateProxyGroup .FirstRow .Name {
	margin-bottom: 20px;
	width: 40%;
	min-width: 130px;
	max-width: unset; /* To avoid conflict with existing Name class */
}
.CreateProxyGroup .FirstRow .Channel {
	margin-bottom: 20px;
	width: 25%;
	padding-left: 20px;
	min-width: 130px;
}
.CreateProxyGroup .FirstRow .Sharing {
	margin-bottom: 20px;
	display: flex;
	margin-left: auto;
	padding-left: 20px;
}
.CreateProxyGroup .CreateProxyGroupFooter {
	display: flex;
	justify-content: space-between;
}
.CreateProxyGroup .CreateProxyGroupFooter .Buttons {
	display: flex;
	justify-content: flex-end;
}
.CreateProxyGroup .CreateProxyGroupFooter .Buttons button {
	margin-left: 10px;
}

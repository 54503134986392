.PacyConfigsContainer .AddProxyGroupButton {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 10px;
}
.PacyConfigsContainer .PacyConfigLoader {
	position: fixed;
	top:0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 9999;
}
.PacyConfigsContainer .PacyConfigs .PacyConfigsHeader {
	display: flex;
	justify-content: space-between;
}
.PacyConfigsContainer .PacyConfigs .PacyConfigsHeader .HeaderButtons {
	display: grid;
	grid-auto-flow: column;
	grid-column-gap: 10px;
}
.PacyConfigsContainer .AddProxyGroupButton .Button {
	display: flex;
	align-items: center;
	margin-right: 1%;
}
.PacyConfigsContainer {
	margin: auto;
}
.PacyConfigsContainer .PacyConfigs {
	padding: 20px;
	margin: 20px;
}
.PacyConfigsContainer .PacyConfigs .ExpansionPanel {
	background-color: rgb(250, 250, 250)!important;
}
.PacyConfigsContainer .PacyConfigs .ProxyGroup {
	display: flex;
	flex-direction: column;
}
.SetAmountModal {
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 20px;
}
.SetAmountModal .SetAmountModalFooter {
	display: flex;
	justify-content: flex-end;
}
.PacyConfigsContainer .ErrorSnackBar {
	background-color: #e53935;
	display: flex;
	align-items: center;
}
.PacyConfigsContainer .ErrorSnackBar .Error {
	display: flex;
	align-items: center;
}
.PacyConfigsContainer .ErrorSnackBar .Error .Icon {
	margin-right: 10px;
}
.PacyConfigsContainer .ErrorSnackBar .Error .Message  {
	font-weight: bold;
}
.ToggleContainer {
	padding: 20px;
	margin: 20px;
	height: 130px;
}
.ToggleContainer .ToggleSwitch {
	display: flex;
	align-items: center;
	font-weight: bold;
  	color: #212121;
	float: right;
}

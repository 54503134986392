.CreateAgentStep1 {
	width: 30vw;
}
.CreateAgentStep1 .CreateAgentStep1Header {
	display: flex;
	justify-content: space-between;
}
.CreateAgentStep1 .Content {
	padding: 20px;
	background-color: rgba(0,0,0,0.01);
	text-align: center;
}

.CreateAgentStep1 .Content .ProviderList button{
	width: 80%;
	margin-top: 10px
}

.LengthLimitedTextInput .InputArea {
	display: grid;
	grid-template-columns: 1fr max-content;
	font-weight: normal;
	font-size: 14px;
	padding: 4px 0px 4px 10px;
}
.LengthLimitedTextInput .InputArea .LimitIndicator {
	align-self: end;
	margin-right: 5px;
}
.LengthLimitedTextInput .ErrorText div {
	padding-bottom: 4px;
}

.Footer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: fixed;
	bottom: 0;
	left: 0;
	height: 75px;
	width: 100vw;
	padding: 20px;
	background-color: white;
	z-index: 10;
}
.Footer .SaveButton {
	margin-left: 10px;
	margin-right: 10px;
}

.Footer .MenuButton {
	margin-right: 16px;
	display: flex;
	align-items: center;
}

.Footer .Icon {
	margin-right: 5px;
}
.CreateProxyGroupModal {
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.CreateProxyModal {
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.CreateAgentModal {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

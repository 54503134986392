.AdsEdit {
	display: grid;
	grid-template-columns: 2fr 2fr 1fr;
	align-items: center;
}
.AdsEdit .Form {
	padding-top: 35px;
}
.AdsEdit .Tags {
	margin-top: 10px;
}
.AdsEdit .Form .Delete {
	display: flex;
	justify-content: flex-start;
	margin-left: 10px;
	margin-top: 10px;
}
.AdsEdit .AdCopies .AdCopiesCards {
	max-height: 400px;
	overflow: auto;
	padding-bottom: 1px;
}
.AdsEdit .AdCopies .Add {
	cursor: pointer;
	width: 100%;
	min-height: 80px;
	margin-top: 20px;
	display: flex;
	align-items: center;
	color: #797878;
}
.AdsEdit .AdCopies .Add:hover {
	color: #333333;
}
.AdsEdit .AdCopies .Add .Icon {
	margin-top: 5px;
	padding-left: 10px;
	margin-right: 10px;
}
.AdsEditContainer .Footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 20px;
	position: sticky;
	width: 100%;
}
.AdsEditContainer .Footer .Tip {
	font-style: italic;
}
.AdsEditContainer .Footer .Tip {
	display: flex;
	align-items: center;
	font-size: 0.9rem;
}
.AdsEditContainer .Footer .Tip .Icon {
	margin-right: 5px;
	color: #ffc966;
}
.AdsEditContainer .Footer .Buttons button {
	margin-left: 10px;
}
.PreviewModal {
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.Preview {
	width: 90vw;
	height: 90vh;
	background-color: white;
}

.highlightedRow {
	display: grid;
	grid-template-columns: min-content max-content;
	align-items: center;
	grid-column-gap: 5px;
}
.EmptyMissedOpportunities {
	text-align: center;
	margin-top: 25%;
}

.TrendyCarsDataTable tbody td {
	font-size: 12px;
}
.TrendyCarsDataTable thead {
	font-size: 13px;
}
.TrendyCars .pagination.react-bootstrap-table-page-btns-ul {
	display: none;
}
.TrendyCarsDataTable th {
	font-size: 1.5em !important;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 300;
	line-height: 1.5em;
}
.TrendyCarsDataTable .fixedSizedColumn {
	width: 115px;
}

.SetupTable table {
	width: 100%;
	text-align: center;
}
.SetupTable table td {
	padding: 15px 0px;
}
.SetupTable table th {
	text-align: center;
	padding: 5px 0px;
	vertical-align: middle;
}
.SetupTable table thead tr {
	background-color: #818285;
	color: white;
	height: 50px;
}
.SetupTable table tbody tr:nth-child(odd) {
	background-color: #e7e7e8;
}
.SetupTable table tbody tr:nth-child(even) {
	background-color: #f7f8f8;
}

.SetupTable table th:first-child,
.SetupTable table td:first-child {
	text-align: left;
	padding-left: 20px;
}

.SetupTable table .ColMonth.Index0 {
	padding-left: 5px;
}
.SetupTable table .ColMonth.Index1 {
	padding-right: 48px;
}
.SetupTable table .ColMonth.Index2 {
	padding-right: 94px;
}

.SetupTable table .ColMonth.CurrentMonth {
	font-size: 20px;
	font-weight: 600;
}
.SetupTable table .AmountCell.Editable {
	cursor: pointer;
}
.SetupTable table .ColMonth.PastMonth {
	color: #b9b9b9;
}

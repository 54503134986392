.AdGroupCreator .Icon {
	margin-right: 5px;
}
.AdGroupCreator .MuiStepIcon-text {
	fill: white;
	font-weight: bold;
}
.AdGroupCreator .Footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.AdGroupCreator .Footer .Messages {
	padding-left: 2%;
}
.AdGroupCreator .Footer .Messages .Error {
	display: flex;
	align-items: center;
	color: #e53935;
}
.AdGroupCreator .Footer .Buttons {
	display: flex;
	justify-content: flex-end;
	padding-right: 2%;
}

.Alert {
	display: grid;
	grid-template-columns: 15% 80% 4%;
	margin: 10px 0px;
}
.AlertTitle {
	position: relative;
	font-size: 16px;
	padding-left: 10%;
	padding-top: 10px;
	width: 90%;
	height: 48px;
	color: #fff;
	height: 100%;
}
.AlertTitle:after {
	content: "";
	position: absolute;
	top: 7px;
	right: -17px;
	width: 34px;
	height: 34px;
	background: inherit;
	transform: rotate(45deg);
}
.AlertText {
	height: 48px;
	font-size: 14px;
	color: #fff;
	padding-left: 30px;
	padding-top: 10px;
}
.AlertClose {
	height: 48px;
	padding-left: 20%;
	padding-top: 10px;
}

@media screen and (max-width: 991px) {
	.Alert {
		grid-template-columns: 30% 65% 4%;
	}
	.AlertTitle {
		font-size: 60%;
		padding-left: 1%;
	}
	.AlertText {
		font-size: 75%;
	}
}

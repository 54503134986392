.PacyEnablement {
	width: 100%;
	display: grid;
	grid-template-columns: 3fr 4fr;
}
.PacyEnablement .Author {
	text-align: right;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.PacyEnablement .Author p em {
	font-weight: bold;
}
.PacyEnablement .Author p {
	margin-left: 10px;
}
.PacyEnablement .Content {
	display: flex;
	justify-content: space-between;
}
.PacyEnablement .Content .title {
	font-weight: bold;
}
.Diff {
	display: inline-grid;
	grid-template-columns: auto auto;
}
@media screen and (max-width: 1780px) {
	.PacyEnablement {
		grid-template-columns: 3fr 3fr;
	}
}
@media screen and (max-width: 1500px) {
	.PacyEnablement {
		grid-template-columns: 4fr 3fr;
	}
}
@media screen and (max-width: 1300px) {
	.PacyEnablement {
		grid-template-columns: 5fr 3fr;
	}
}

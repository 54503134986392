.sidebar .nav .nav ul {
	display: none;
}
.sidebar .nav .nav.active ul {
	display: inline;
}
.sidebar {
	background-color: #fff;
	z-index: 101;
}
.sidebar .nav .active ul li {
	padding-left: 10%;
	margin-top: 10px;
}

@media screen and (max-width: 991px) {
	.sidebar .logo {
		background-color: white;
		textalign: "center";
	}
	.sidebar .logo img {
		max-height: 70px;
		min-height: 70px;
	}
	.sidebar .account-selector {
		max-height: 50px;
		min-height: 50px;
	}
	.sidebar::before {
		background-color: white;
	}
}
.sidebar .nav .nav {
	margin-top: 10px;
}
.sidebar .account-selector:after {
	z-index: 3800;
	content: "";
	position: absolute;
	right: 10%;
	height: 1px;
	width: 80%;
	background-color: rgba(180, 180, 180, 0.3);
}
.sidebar .react-bootstrap-table-pagination {
	display: none;
}
.sidebar-wrapper {
	overflow-y: auto !important;
	overflow-x: hidden !important;
}
.sidebar .sidebar-wrapper {
	height: calc(100vh - 154px) !important;
}
.menu_closed .sidebar .sidebar-wrapper {
	height: calc(100vh - 170px) !important;
}


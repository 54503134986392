.AudienceAdCreator {
	padding: 20px;
	margin: 20px;
	display: flex;
	flex-direction: column;
}
.AudienceAdCreator .Buttons {
	display: flex;
	justify-content: flex-end;
}
.AudienceAdCreator hr {
	margin: 30px 0px 20px 0px;
}
.AudienceAdCreator .AudienceSelector .Instructions {
	margin-bottom: 30px;
}
.AdsEditModal {
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.AdsEditModal .AudienceAdsEdit {
	padding: 20px;
	width: 90vw;
	background-color: white;
}

.OverviewDataTable .order {
	display: none;
}
.DataTablekeywordsForFriend {
	display: grid;
	grid-template-rows: 50px max-content;
}
.DataTablekeywordsForFriend .Header {
	display: grid;
	grid-template-columns: max-content 1fr;
	align-items: center;
	white-space: nowrap;
	padding: 8px;
}
.DataTablekeywordsForFriend .Header button {
	margin-left: auto;
}

.ShapeSelector .ChoicesList {
	display: grid;
	grid-template-columns: max-content;
	grid-row-gap: 5px;
}
.ShapeSelector .ShapeChoice {
	cursor: pointer;
	background-color: #cccccc;
	padding: 2px;
}
.ShapeSelector .ShapeChoice:hover {
	background-color: #bbbbbb;
}

.BudgetsInsight {
	padding: 10px;
	width: 100%;
	border: 1px solid #ddd;
	box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.BudgetsInsight .Header {
	display: grid;
	grid-template-columns: 1fr 1fr;
}
.BudgetsInsight .Header .CloseButton {
	justify-self: end;
}
.BudgetsInsight .Header .CloseButton:hover:after {
	color: black;
	cursor: pointer;
}
.BudgetsInsight .Header .CloseButton:after {
	display: inline-block;
	content: "\00d7";
	font-size: 40px;
	color: #aaa;
	padding: 5px 5px 0;
}
@media screen and (min-width: 1201px) {
	.BudgetsInsight {
		border-color: #00b1e4;
	}
}

.TrendyCarsDataTable tbody td {
	font-size: 12px;
}
.TrendyCarsDataTable thead {
	font-size: 13px;
}
.TrendyCarsDataTable th {
	font-size: 1.5em !important;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 300;
	line-height: 1.5em;
}
.TrendyCarsDataTable .fixedSizedColumn {
	width: 115px;
}
.HeaderTitle {
	display: flex;
	align-items: center;
}
.HeaderTitle h4 {
	margin-right: 5px;
}

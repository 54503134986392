.CampaignAdCopies {
	display: grid;
	grid-auto-rows: 1fr;
	grid-row-gap: 40px;
	padding: 10px;
}
.CampaignAdCopies .AdCopiesRow {
	display: grid;
	grid-template-columns: 80px 1fr;
	overflow-x: hidden;
}
.CampaignAdCopies .AdCopiesRow.RLSA {
	background-color: #f5fcfe;
}
.CampaignAdCopies .AddIcon {
	margin: 10%;
}

.MonthSetupModal .ProxiesValueSetup {
	margin-top: 10px;
	display: grid;
	grid-template-columns: max-content max-content;
	align-items: center;
	grid-column-gap: 5px;
	grid-row-gap: 2px;
}
.ProxyRow {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.ProxyRowDetails {
	display: flex;
	align-items: center;
	margin-left: 30px;
}

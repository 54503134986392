.CreateKeywords {
	margin: 20px;
	padding: 20px;
}
.CreateKeywords .Edit {
	display: flex;
	flex-direction: row;
}
.CreateKeywords .Edit .KeywordsInputContainer {
	flex-grow: 1;
	flex-basis: 0;
}

.AddGuidelineListFilters {
	min-width: 80%;
	padding: 10px;
}
.AddGuidelineListFilters .Type {
	display: flex;
}
.AddGuidelineListFilters .Details {
	display: flex;
	flex-direction: column;
}
.AddGuidelineListFilters .Details ul {
	width: 100%;
}
.AddGuidelineListFilters .Details .ClearAll {
	text-transform: uppercase;
	display: block;
	padding-top: 10px;
	color: #666;
	font-weight: bold;
}
.AddGuidelineListFilters .Details .ClearAll:hover {
	color: #00b2e2;
	cursor: pointer;
}
.AddGuidelineListFilters .Details .ClearAll p span {
	padding-top: 6px;
	padding-right: 4px;
}

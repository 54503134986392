.EditStrategyModalContent {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-areas:
		"Active   Geotarget  "
		"Active   BidSetting "
		"Bidding  Bidding  "
		"BiddingStrategyType BiddingStrategyType "
		"Properties Properties "
		"FinalUrl FinalUrl "
		;
	padding-left: 25px;
	padding-right: 25px;
	grid-row-gap: 20px;
}
.EditStrategyModalContent .StrategyActive {
	grid-area: Active;
}
.EditStrategyModalContent .StrategyGeoTargeting {
	justify-self: end;
	grid-area: Geotarget;
	width: 320px;
}
.EditStrategyModalContent .StrategyBiddingStrategy {
	grid-area: Bidding;
}
.EditStrategyModalContent .StrategyFinalUrl {
	grid-area: FinalUrl;
}
.EditStrategyModalContent .BidSetting {
	justify-self: end;
	grid-area: BidSetting;
}
.EditStrategyModalContent .Properties {
	grid-area: Properties;
}

.EditStrategyModalContent .TargetImpressionShare {
	display: flex;
	height: 125px;
}

.EditStrategyModalContent .TargetImpressionShare p {
	float: right;
	padding-top: 10px;
	font-size: small;
	color: red;
}

.EditStrategyModalContent .TargetImpressionShareLeft {
	width: 45%;
}

.EditStrategyModalContent .TargetImpressionShareRight {
	justify-self: end;
}

.TargetImpressionShareField {
	display: flex;
	align-items:center;
	padding-bottom: 20px;
}

.EditStrategyModalContent .TargetImpressionShare .Percent {
	border: 0;
	outline: 0;
	width: 75px;
	text-align: right;
	padding-left: 5px;
}

.EditStrategyModalContent .TargetRoas {
	border: 0;
	outline: 0;
	width: 75px;
	padding-left: 5px;
}

.EditStrategyModalContent .MaxCpc {
	border: 1px solid black;
	padding-left: 5px;
	width: 120px;
}

.EditStrategyModalContent .TargetCpa {
	border: 1px solid black;
	padding-left: 5px;
	width: 120px;
}

.EditStrategyModalContent .FilterableSelect select {
	padding-top: 3px;
	padding-bottom: 2px;
}
.EditStrategyModalContent .EditStrategyModalInput .InputLabel {
	font-weight: 500;
}

.AccountWazeView,
.AccountPreferencesView {
	border: 1px solid #ddd;
	padding: 20px;
	border-radius: 6px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}
.AccountWazeView .TopPart,
.AccountPreferencesView .TopPart {
	margin-bottom: 10px;
}
.AccountWazeView .BotPart,
.AccountPreferencesView .BotPart {
	display: grid;
	grid-template-columns: max-content max-content;
	grid-column-gap: 10px;
	align-items: center;
}
.AccountWazeView .BotPart .Label,
.AccountPreferencesView .BotPart .Label {
	font-weight: bold;
}
.AccountWazeView .WazeCompanySelect.Edited select,
.AccountPreferencesView .WhiteLabelSelect.Edited select {
	border: yellow 2px solid;
}

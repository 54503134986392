.CreateProxy {
	width: 30vw;
}
.CreateProxy .CreateProxyHeader {
	display: flex;
	justify-content: space-between;
}
.CreateProxy .CreateProxyHeader .Active {
	display: flex;
	align-items: center;
	font-size: 1.1rem;
}
.CreateProxy .Content {
	padding: 20px;
	background-color: rgba(0,0,0,0.01);
}

.CreateProxy .MaxSharing {
	width: 350px;
}
.CreateProxy .SecondRow {
	display: flex;
	justify-content: space-between;
}
.CreateProxy .FirstRow .Boost {
	display: flex;
	align-items: center;
}
.CreateProxy .CreateProxyFooter {
	display: flex;
	justify-content: flex-end;
}

.KeywordsInputContainer .ErrorSnackBar {
	background-color: #e53935;
	display: flex;
	align-items: center;
}
.KeywordsInputContainer .ErrorSnackBar .Error {
	display: flex;
	align-items: center;
}
.KeywordsInputContainer .ErrorSnackBar .Error .Icon {
	margin-right: 10px;
}
.KeywordsInputContainer .ErrorSnackBar .Error .Message {
	font-weight: bold;
}

.OverviewElement {
	border: 1px solid #bfbfbf;
}
.OverviewElement.Graph {
	height: 250px;
}
.OverviewRapport {
	border: 1px solid #bfbfbf;
	display: grid;
	height: 460px;
	align-content: fill;
	justify-content: fill;
}
.OverviewRapport h4 {
	margin: 8px;
}
.OverviewRapport .DonutDiv {
	height: 389px;
}
.Overview .GraphNoData {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.CulledVehicles {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.ExpansionPanelContainer {
  padding: 10px;
  padding-top: 0px;
  padding-left: 20px;
}
.ExpansionPanelDetails {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}
.WarningYellow {
  color: #ff9800;
}
.SafeBlue {
  color: #0fbeec;
}
.NoRows {
  text-align: center;
  margin-bottom: 10px;
}
.CulledVehiclesTitle {
  display: flex;
  align-items: center;
}
.CulledVehiclesIcon {
  margin-right: 6px;
}
.AdCopyEditingForm .Add {
  cursor: pointer;
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  color: #797878;
  margin-bottom: 35px;
}
.AdCopyEditingForm .Add:hover {
  color: #333333;
}
.AdCopyEditingForm .Icon {
  margin-top: 5px;
  padding-left: 10px;
  margin-right: 10px;
}
.AdCopyEditingForm .AddLabel {
  font-size : 14px;
  font-weight:bold;
}
.AdCopyEditingForm .Headlines .HeadLine,
.AdCopyEditingForm .Descriptions .Description {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-template-rows: 1fr;
  align-items: center;
}
.AdCopyEditingForm .Pinned {
  color: #00B2E3;
  font-size: 1.2rem;
}
.AdCopyEditingForm .Unpinned {
  color: grey;
  opacity: 0.2;
  font-size: 1.2rem;
}
.AdCopyEditingForm .Pinned:hover,
.AdCopyEditingForm .Unpinned:hover {
  cursor: pointer;
}
@media only screen (min-width: 850px){
  .AdCopyEditingForm {
    grid-template-columns: 1fr;
  }
  .AdCopyEditingForm .Descriptions .Description {
    margin-bottom: 20px;
    grid-column-end: 2;
  }
}
@media only screen (min-width: 500px){
  .AdCopyEditingForm .Descriptions .Description {
    margin-bottom: 20px;
    grid-column-end: 2;
    grid-template-columns: 1fr;
  }
  .AdCopyEditingForm .Headlines .HeadLine {
    margin-bottom: 20px;
    grid-column-end: 2;
  }
}
.AdCopyEditingForm .PinNumber {
  color: #00B2E3;
  display: flex;
  align-items: center;
  font-size: 12px;
}

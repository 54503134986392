.KeywordsGroup {
	display: grid;
}
.KeywordsGroupTitle {
	font-size: 18px;
	font-weight: bold;
}
.KeywordsGroupTitle,
.KeywordsTextArea {
	display: grid;
	padding: 5px;
	margin: 5px;
	align-self: center;
	text-align: center;
}
.KeywordsButtons {
	display: grid;
	padding-top: 25px;
	align-self: center;
	text-align: center;
}
.KeywordsButtons button {
	margin-bottom: 10px;
}
.KeywordsGroup .KeywordsButtons .ResetInitialKeywords {
	background-color: transparent;
	color: #ff5858;
	border-color: #e4e4e4;
}
.KeywordsGroup .KeywordsButtons .ResetInitialKeywords:focus,
.KeywordsGroup .KeywordsButtons .ResetInitialKeywords:hover {
	background-color: transparent;
	color: #ff5858;
	border-color: #e4e4e4;
}
.KeywordsGroup .KeywordsButtons .SubmitChangesOnKeywords {
	width: 100%;
}
@media screen and (min-width: 991px) {
	.KeywordsGroup {
		grid-template-columns: 10% 40% 40% 10%;
		border: solid 1px grey;
		margin: 10px;
		padding: 10px 40px 10px 0;
	}
	.KeywordsGroupTitle {
		transform: rotate(-90deg);
	}
}
@media screen and (max-width: 990px) {
	.KeywordsGroup {
		grid-auto-flow: row;
	}
	.KeywordsGroup {
		margin-bottom: 30px;
	}
	.KeywordsButtons {
		padding-top: 5px;
	}
	.KeywordsTextArea textarea {
		font-size: 12px;
	}
}
.readOnlyBanner {
	position: relative;
	z-index: 1;
}
.readOnlyBanner:after {
	content: "Read only";
	position: absolute;
	top: -23px;
	right: -35px;
	width: auto;
	height: 34px;
	padding-left: 5px;
	padding-right: 5px;
	background: #ffdb4c;
	color: black;
	opacity: 1;
	font-weight: bold;
	transform: rotate(30deg);
	font-size: 11px;
	line-height: 34px;
	font-family: ibm-plex-sans, "Helvetica Neue", Arial, sans-serif;
	box-shadow: rgba(0, 0, 0, 0.3) 4px 6px 4px;
}

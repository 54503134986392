.Hint {
	display: flex;
	align-items: flex-start;
}
.Hint .Tip {
	font-size: 0.9rem;
	font-style: italic;
}
.Hint .Icon {
	color: #ffc966;
	margin-right: 5px;
}

.DesignedTooltip {
	background-color: white;
	position: relative;
	padding: 20px;
	opacity: 1;
	max-width: 200px;
	text-align: center;
}

.DesignedTooltip .CornerArrow {
	position: absolute;
	top: 0;
	right: 0;
	border-color: transparent;
	border-style: solid;
	border-width: 0.9em;
}

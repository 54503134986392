.ManagerContainer {
	margin-bottom: 5px;
}
.ManagerControls {
	display: flex;
	justify-content: space-between;
	width: 250px;
	margin-left: 20px;
}
.ManagerControls select {
	width: 100px;
}

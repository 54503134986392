.TemplateNameInput,
.TemplateFiltersInput,
.TemplateSizePerPageInput {
	max-width: 500px;
}
.TemplateFiltersInput.Edited,
.TemplateNameInput.Edited,
.TemplateSizePerPageInput.Edited {
	border-color: yellow;
	border-width: 2px;
}

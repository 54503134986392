.SoldCarsDataTable tbody td {
	font-size: 12px;
}
.SoldCarsDataTable thead {
	font-size: 13px;
}
.SoldCarsDataTable .YMM {
	width: 50%;
}
.SoldCarsDataTable .dateColumn {
	width: 80px;
}
.SoldCarsDataTable tbody td {
	font-size: 12px;
}
.SoldCarsDataTable thead {
	font-size: 13px;
}
.SoldCarsDataTable .YMM {
	width: 50%;
}
.SoldCarsDataTable .dateColumn {
	width: 80px;
}
.SoldCarsDataTable .CSVButton {
	float: right;
	margin-bottom: 10px;
}
.handleManualLineBreak {
	white-space: pre;
}

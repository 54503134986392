.AdCopyEditor .Headlines .HeadLine {
	padding-bottom: 10px;
}
.AdCopyEditor .Descriptions .Description {
	margin-top: 10px;
}
.CulledVehicles {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
}
.ExpansionPanelContainer {
	padding: 10px;
	padding-top: 0px;
	padding-left: 20px;
}
.ExpansionPanelDetails {
	display: flex;
	flex-direction: column;
	padding-left: 10px;
}
.CulledVehiclesList {

}
.CulledVehiclesList li {
	padding-left: 10px;
}
.WarningYellow {
	color: #ff9800;
}
.SafeBlue {
	color: #0fbeec;
}
.NoRows {
	text-align: center;
	margin-bottom: 10px;
}
.CulledVehiclesTitle {
	display: flex;
	align-items: center;
}
.CulledVehiclesIcon {
	margin-right: 6px;
}

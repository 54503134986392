.ReportDataTable tbody{
	font-size:12px;
}
.TemplateEditor .Buttons{
	margin-top:15px;
}
.TemplateEditor .Buttons .Reset{
	float:right;
	margin-left:10px;
}
.TemplateEditor .Buttons .Delete{
	float:right;
	background-color: #ccc;
}
.TemplateEditor .Buttons .Delete:hover{
	background-color: red;
}
.TemplateEditor .Buttons .Cancel{
	margin-left:10px;
	background-color: #ccc;
}
.TemplateEditor .Buttons .Cancel:hover{
	background-color: red;
}
.TemplateEditor .Options{
	display:grid;
	grid-row-gap:10px;
}
.TemplateEditor .NameManager,
.TemplateEditor .SizePerPageManager{
	display:inline-block;
	margin-bottom:10px;
}
.TemplateEditor .NameManager .TooShort{
	border-width: 2px;
	border-color: red;
}
.TemplateEditor .AlignedItems{
	display: grid;
	grid-template-columns: min-content max-content;
	white-space: nowrap;
}
.TemplateEditor .AlignedItems span {
	display: flex;
	align-items: center;
	margin-right: 10px;
	height: 40px;
}
.ColumnManager{
	display : grid;
	grid-template-rows: 50px 1fr;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 15px;
	grid-row-gap: 10px;
}
.ColumnManager .ColumnsExchangeContext{
	grid-column-start: 1;
	grid-column-end: 3;
}
.ColumnManager .ContainerTitle{
	text-align:center;
}
.DragColumn{
	display:grid;
	grid-template-columns: 1fr 2fr;
	align-items: center;
	height:60px;
	box-shadow: 0 4px 3px 0 rgba(0, 0, 0, 0.2);
	padding:10px;
	margin:10px;
}
.ColumnsContainer{
	height:100%;
}
.ColumnsContainer.DragHappening{
	background-color:rgba(127,127,127, 0.6);
}
.ColumnsExchangeContext{
	display:grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 15px;
}
.TemplateNameInput,
.TemplateFiltersInput,
.TemplateSizePerPageInput{
	max-width:500px;
}
.TemplateFiltersInput.Edited,
.TemplateNameInput.Edited,
.TemplateSizePerPageInput.Edited{
	border-color:yellow;
	border-width:2px;
}
.TemplateActions {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 1fr;
	max-width: 300px;
	align-items: center;
	justify-items: left;
	margin-top: 25px;
	margin-bottom: 15px;
	row-gap: 15px;
}
.TemplateActions .TemplateChooser {
	grid-column-start: 1;
	grid-column-end: 3;
}
.TemplateActionsChooserContainer {
	width: 100%;
	display: flex;
	align-items: center;
}
.TemplateActionButtons {
	display: flex;
	justify-content: space-between;
	width: 100%;
}
.ReportTable .CSVButton{
	float:right;
	margin-bottom:10px;
}

.OppTrendHighlighter {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 20px;
}
@media screen and (max-width: 850px) {
	.OppTrendHighlighter {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr;
		grid-row-gap: 20px;
	}
}

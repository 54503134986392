.ComparisonTooltip {
	background-color: white;
	padding: 10px;
	border: 1px solid #ddd;
	display: grid;
	grid-row-gap: 5px;
}
.ComparisonTooltip .KeyData {
	display: grid;
	grid-row-gap: 0px;
}
.ComparisonTooltip .KeyData .LineData {
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100%;
	font-size: 12px;
	line-height: 18px;
}
.ComparisonTooltip .KeyData .LineData .YValue {
	text-align: right;
	font-weight: bold;
}

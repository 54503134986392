.MapWithEditor {
	text-align: right;
}
.MapWithEditor > div {
	display: inline-block;
	margin-left: 15px;
}
.MapWithEditor .ResetModalButton {
	margin-top: 20px;
}
.MapWithEditor .ResetModalButton button {
	z-index: 100;
}
.MapWithEditor .SaveButton button {
	z-index: 100;
}
.MapEditorTopButtons {
	height: "40px";
	text-align: "right";
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 20px;
	grid-template-areas: "goBAck optionalButton reset ";
}
.MapEditorTopButtons .ModalWrapperOptionalButton {
	grid-area: optionalButton;
	text-align: center;
	margin-left: 0px;
}
.MapWithEditor .GeoBackButton {
	width: 100px;
}
.readOnlyBanner {
	position: relative;
	z-index: 1;
}
.readOnlyBanner:after {
	content: "Read only";
	position: absolute;
	top: -23px;
	right: -35px;
	width: auto;
	height: 34px;
	padding-left: 5px;
	padding-right: 5px;
	background: #ffdb4c;
	color: black;
	opacity: 1;
	font-weight: bold;
	transform: rotate(30deg);
	font-size: 11px;
	line-height: 34px;
	font-family: ibm-plex-sans, "Helvetica Neue", Arial, sans-serif;
	box-shadow: rgba(0, 0, 0, 0.3) 4px 6px 4px;
}

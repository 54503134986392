.ChoosingCard {
	padding: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.ChoosingCard .Option {
	padding: 20px;
	margin: 20px;
	display: flex;
	align-items: center;
}
.ChoosingCard .Option span {
	margin-right: 10px;
}
.ChoosingCard .Option:hover {
	cursor: pointer;
	background-color: #00b2e3;
	color: white;
}

.AccountSelection .AccountsList {
	height: 269px;
}
.AccountSelection .AccountsList .Account {
	display: grid;
	place-content: center;
	padding: 14px;
	border: 1px solid #dddddd;
	border-bottom: none;
	cursor: pointer;
}
.AccountSelection .AccountsList .Account:hover {
	background-color: #dddddd;
}
.AccountSelection .AccountsList .Account:last-of-type {
	border-bottom: 1px solid #dddddd;
}
.AccountSelection .AccountsList .Account.Selected {
	border: 2px solid #00addb;
}
.AccountSelection .AccountsList .Account.NoAccount {
	cursor: default;
}
.AccountSelection .AccountsList .Account.NoAccount:hover {
	background-color: transparent;
}

.AccountSelection .InputWrapper {
	border: 1px solid #dddddd;
	border-bottom: none;
	width: 100%;
	height: 50px;
	padding: 12px 7px;
}
.AccountSelection .InputWrapper .AccountsInput {
	font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
	font-size: 14px;
	font-weight: 400;
	width: 100%;
	height: 100%;
	border: none;
	border-bottom: 1px solid #dddddd;
}
.AccountSelection .InputWrapper .AccountsInput:focus {
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
		0 0 8px rgba(102, 175, 233, 0.6);
}

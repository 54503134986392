.IdentityVariants {
	display: flex;
	flex-direction: column;
	padding: 20px;
}
.IdentityVariants .Buttons {
	display: flex;
	justify-content: flex-end;
}
.IdentityVariants .Header .Languages {
	display: flex;
	flex-direction: row;
}
.UnfilledError {
	font-size: 14px;
	margin-left: 16px;
}
.WarningYellow {
	color: #ff9800;
}

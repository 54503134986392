.RatioCharParts {
	margin: 0px 12px 20px 12px;
	box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42),
		0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.RatioCharPart {
	display: inline-block;
	text-align: center;
	font-size: 24px;
	padding: 12px 0px;
}

.ForceCreateIdentityVariants {
	margin-top: 25px;
}
.ForceCreateIdentityVariants .WarningYellow {
	margin-right: 6px;
}
.ForceCreateIdentityVariants .MissingVariantsTitle{
	display: grid;
	align-items: center;
	grid-template-columns: min-content max-content;
}

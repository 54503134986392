.SoldCarsTable {
	display: grid;
	grid-template-columns: 2fr minmax(500px, 4fr) 0px 260px;
	grid-template-areas: "KPIs Table Pagination Percent";
	height: 300px;
	border: 1px solid #ddd;
	box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.SoldCarsTable .react-bootstrap-table::-webkit-scrollbar {
	width: 0 !important;
}
.SoldCarsTable .react-bootstrap-table {
	overflow-y: auto;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	overflow-x: hidden;
}
.SoldCarsTable .react-bootstrap-table {
	height: 300px;
	grid-area: Table;
}
.SoldCarsTable .react-bootstrap-table .normalSizedDatatable {
	height: 300px;
}
.SoldCarsTable .pagination.react-bootstrap-table-page-btns-ul {
	display: none;
	grid-area: Pagination;
}
.SoldCarsTable .SoldCarsTableKPIs {
	grid-area: KPIs;
	display: grid;
	grid-template-rows: 2fr 22px 2fr;
}
.SoldCarsTable .SoldCarsTableKPIs > * {
	align-self: center;
	margin-bottom: 11px;
	margin-left: 8px;
}
.SoldCarsTable .SoldCarsTableKPIs > *:first-child {
	margin-top: 11px;
	margin-bottom: 0;
}
.SoldCarsTable .SoldCarsTableKPIs hr {
	margin: 10px 0px;
	text-align: center;
}
.SoldCarsTable-empty {
	display: grid;
	text-align: center;
	align-items: center;
	height: 100%;
}
.SoldCarsTable .SeeMore {
	text-align: right;
	height: 40px;
}
.SoldCarsTable .ProportionKPIWrapper {
	grid-area: Percent;
}
.SoldCarsTable .ProportionKPIWrapper {
	grid-area: Percent;
}
.SoldCarsTable .ProportionKPI {
	display: grid;
	align-content: center;
	text-align: center;
	margin: 10px;
	margin-top: 20px;
}
.SoldCarsTable .ProportionKPI .title {
	font-size: 14px;
	color: darkgrey;
	margin: 0;
}
.SoldCarsTable .ProportionKPI div.percent {
	font-size: 6em;
	font-weight: bold;
	color: #00b1e4;
	margin-bottom: 30px;
	margin-top: 30px;
}
.SoldCarsTable .ProportionKPI hr {
	border-top: 1px solid #ddd;
}
.SoldCarsTable .EmptySoldCarsTable {
	text-align: center;
	margin-top: auto;
	margin-bottom: auto;
	margin-left: 10px;
	margin-right: 10px;
}
@media screen and (max-width: 1300px) {
	.SoldCarsTable {
		grid-template-columns: 2fr minmax(500px, 4fr) 0px 200px;
	}
	.SoldCarsTable .ProportionKPI div.percent {
		font-size: 5em;
	}
}
@media screen and (max-width: 1200px) {
	.SoldCarsTable {
		grid-template-columns: 150px minmax(200px, 2fr) minmax(auto, 4fr) 0px;
		grid-template-areas: "KPIs Percent Table Pagination";
	}
	.SoldCarsTable .KPIs {
		margin-right: 10px;
	}
	.SoldCarsTable hr {
		visibility: hidden;
	}

	.SoldCarsTable .ProportionKPI hr {
		display: none;
	}

	.SoldCarsTable .ProportionKPI div.percent {
		font-size: 4em;
	}

	.SoldCarsTable .ProportionKPI .title {
		font-size: 12px;
	}
}
@media screen and (max-width: 900px) {
	.SoldCarsTable {
		grid-template-columns: 1fr;
		grid-template-areas:
			"Percent"
			"KPIs";
		height: inherit;
	}
	.SoldCarsTable .react-bootstrap-table,
	.SoldCarsTable .react-bootstrap-table-pagination {
		display: none;
	}
	.SoldCarsTable hr {
		visibility: hidden;
	}
	.SoldCarsTable .EmptySoldCarsTable {
		margin-right: 20px;
		margin-left: 20px;
		margin-bottom: 20px;
	}
}

.SoldCarsTable .SoldCarsTableKPIs {
	grid-area: KPIs;
	display: grid;
	grid-template-rows: 2fr 22px 2fr;
}
.SoldCarsTable .SoldCarsTableKPIs > * {
	align-self: center;
	margin-bottom: 11px;
	margin-left: 8px;
}
.SoldCarsTable .SoldCarsTableKPIs > *:first-child {
	margin-top: 11px;
	margin-bottom: 0;
}
.SoldCarsTable .SoldCarsTableKPIs hr {
	margin: 10px 0px;
	text-align: center;
}

.CreateProxyParams {
	width: 100%;
	height: 100%;
}
.CreateProxyParams .CreateProxyContent .SelectProxyGroup {
	padding: 10px;
}
.CreateProxyParams .CreateProxyContent .SelectProxy {
	padding: 10px;
}
.CreateProxyParams .CreateProxyContent .SelectPossibleAgent {
	padding: 10px;
}
.CreateProxyParams .CreateProxyContent .MaxSharing {
	margin-bottom: 60px;
	width: 100%;
}

.BoundariesSetup {
	margin-top: 70px;
}
.BoundariesSetup .Boundaries {
	display: grid;
	grid-template-columns: 365px 250px 365px;
	align-items: start;
	font-weight: bold;
}
.BoundariesSetup .BoundariesSectionTitle {
	font: normal normal normal 22px/29px Roboto, sans-serif;
	margin-bottom: 30px;
}
.BoundariesSetup .Boundaries input {
	margin-right: 20px;
}
.BoundariesSetup .Boundaries .Selects {
	background-color: #FAFAFA;
	display : flex;
	flex-direction: column;
	padding: 15px;
	align-items: center;
	margin-bottom: 20px;
}
.BoundariesSetup .Input {
	width: 300px;
	margin-bottom: 30px;
}
.BoundariesSetup .Or{
	font: normal normal normal 22px/29px Roboto, sans-serif;
	text-align: center;
	margin-top: 90px;
}
.WarningYellow {
	color: #ff9800;
	display: flex;
	justify-content: center;
	padding : 10px;
}
.BoundariesSetup .Add {
  cursor: pointer;
  min-height: 40px;
  display: flex;
  align-items: center;
  color: #797878;
  justify-self: right;
}
.BoundariesSetup .Add:hover {
  color: #333333;
}
.BoundariesSetup .Add .Icon {
  margin-top: 5px;
  padding-left: 10px;
  margin-right: 10px;
}
.BoundariesSetup .Add .AddLabel {
  font-size: 14px;
  font-weight:bold;
}
.BoundariesSetup .Button {
	color: #707070;
  align-self: end;
  font-weight: bold;
  height: 30px;
  width: 30px;
}

.Tags {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
}
.Tags div {
	margin: 10px;
	padding-right: 15px;
	padding-left: 15px;
}

.GuidelinesDiffFormat.GuidelinesDiffFormatContainer {
	display: flex;
	min-height: 60px;
	align-items: center;
	font-weight: 400;
}
.GuidelinesDiffFormat {
	display: flex;
}
.GuidelinesDiffFormat.Reduction {
	color: #b42828;
	border-color: #b42828;
}
.GuidelinesDiffFormat.Augmentation {
	color: #00a788;
	border-color: #00a788;
}
.GuidelinesDiffFormat.Flat {
	color: #333333;
	border-color: #333333;
}
.GuidelinesDiffFormat .Details {
	display: flex;
	vertical-align: center;
	min-width: 200px;
	justify-content: space-around;
	margin-left: 20px;
	align-items: center;
}

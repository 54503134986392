.NoStrategies {
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.Settings .LanguagesSelect {
	padding-left: 2%;
	max-width: 200px;
}
.Settings .Content {
	padding: 20px;
	margin: 20px;
	display: grid;
	grid-template-areas:
		"Languages 			Languages   		identityVariants    identityVariants	identityVariants"
		"finalUrl 			finalUrl   			identityVariants    identityVariants	identityVariants"
		"biddingStrategy 	biddingStrategy 	geoTargetingType	geoTargetingType	geoTargetingType"
		".                  .                   .             		.					buttons         ";
}
.Settings .Content .IdentityVariants {
	grid-area: identityVariants;
	margin: 10px;
	padding: 10px;
}
.Settings .Content .Languages {
	grid-area: Languages;
	margin: 10px;
}
.Settings .Content .GeoTargetingType {
	grid-area: geoTargetingType;
	margin: 10px;
}
.Settings .Content .BiddingStrategy {
	grid-area: biddingStrategy;
	margin: 10px;
}
.Settings .Content .FinalUrl {
	grid-area: finalUrl;
	margin: 10px;
}
.Settings .Content .Buttons {
	grid-area: buttons;
	margin: 10px;
	display: flex;
	justify-content: flex-end;
}
.Settings .Content .Buttons button {
	margin-left: 10px;
}
.content {
	padding-top: 70px;
	min-height: 100vh; /* will cover the 100% of viewport */
	overflow: hidden;
	display: block;
	position: relative;
	padding-bottom: 100px; /* height of your footer */
}

.ForceCreateIdentityVariants {
	width: 600px;
	margin: auto;
}

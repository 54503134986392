.SimpleAdCopy {
	font-size: 14px;
	line-height: 1.2;
	text-align: left;
	display: grid;
	grid-row-gap: 3px;
}
.SimpleAdCopy .Title {
	font-size: 16px;
	color: #0032d9;
}
.SimpleAdCopy .Url {
	color: #189002;
}
.SimpleAdCopy .Description {
	color: #707070;
}

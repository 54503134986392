.OverviewCard {
	display: grid;
	grid-template-columns: 25% 65% 10%;
	grid-template-rows: 3fr 1fr;
	border: 1px solid #ddd;
	box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2);
	background-color: white;
	min-width: 380px;
}
.OverviewCard .CardIcon {
	justify-self: center;
	align-self: center;
	padding: 10px;
	margin: 5%;
}
.OverviewCard .CardIcon .material-icons {
	font-size: 48px !important;
}
.OverviewCard .CardData {
	display: grid;
	grid-template-rows: 60% 40%;
	padding-top: 2%;
	padding-bottom: 2%;
}
.OverviewCard .CardText {
	font-weight: 800;
	color: #aaaaaa;
	font-size: 30px;
}
.OverviewCard .CardNumberText {
	white-space: nowrap;
	padding-top: 10%;
	font-weight: 900;
	font-size: 2.1em;
}
.OverviewCard .CardPercent {
	position: relative;
	grid-column-start: 1;
	grid-column-end: 4;
}
.OverviewCard .CardPercentText {
	border-radius: 5px;
	color: #fff;
	position: absolute;
	bottom: 10px;
	right: 10px;
	padding: 5px;
}
.OverviewCard .OptionsMenu {
	padding-top: 10px;
	padding-right: 5px;
	text-align: right;
}
.OverviewCard .OptionsMenu .material-icons {
	opacity: 0.5;
	transition: opacity 200ms;
}
.OverviewCard .OptionsMenu .material-icons:hover {
	opacity: 1;
}

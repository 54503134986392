.NumberBreakdown {
	display: grid;
	grid-template-columns: 120px 4fr;
	align-items: center;
}
.NumberBreakdown .Title {
	color: rgb(93, 93, 93);
	font-size: 45px;
	font-weight: 900;
}
.NumberBreakdown .KeyValues {
	grid-column-start: 1;
	grid-column-end: 3;
	display: flex;
	justify-content: flex-start;
	align-self: start;
}
.NumberBreakdown .KeyValues .KeyValueSeparator {
	width: 1px;
	background-color: black;
	opacity: 0.1;
	height: 50px;
	align-self: start;
}
.NumberBreakdown .KeyValues .KeyValue {
	display: grid;
	grid-template-rows: 1fr 1fr;
	text-align: center;
	margin-left: 20px;
	margin-right: 20px;
}
.NumberBreakdown .KeyValues .KeyValue .ValueTitle {
	color: rgb(93, 93, 93);
	white-space: pre;
}
.NumberBreakdown .KeyValues .KeyValue .ValueValue {
	color: #0ab4e3;
	font-weight: 700;
}

.DormantTextInput.Dormant {
	display: grid;
	grid-template-columns: max-content auto;
	grid-column-gap: 5px;
	cursor: pointer;
}
.DormantTextInput.Dormant .EditButton {
	text-align: right;
}

.DormantTextInput.Active .activeDormantInput {
	width: auto;
}

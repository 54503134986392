.GeoTargetingType {
	padding: 10px;
}
.GeoTargetingType .Buttons {
	display: flex;
	justify-content: flex-end;
}
.GeoTargetingType .RadioLabel {
	color: #272727;
}

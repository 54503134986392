.ProxyParams {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 20px;
	margin-bottom: 10px;
}
.ProxyParams .Status {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}
.ProxyParams .Boost {
	display: flex;
	align-items: center;
}
.ProxyParams .MaxSharing {
	display: flex;
	align-items: center;
	width: 300px;
	margin-bottom: 20px;
}
.ProxyParams .Weight {
	display: flex;
	align-items: center;
}
.BoostConfig .AlignItemsCenter {
	display: flex;
	align-items: center;
}
.BoostConfig .BoostConfigFooter {
	display: flex;
	justify-content: flex-end;
}
.BoostConfig .HeaderCell {
	color:white;
	font-weight: bold;
	font-size: 0.9rem;
}
.ProxyParams .FirstRow {
	display: flex;
	justify-content: space-between;
}
.ProxyParams .WeightsAndSharing {
	display: flex;
	flex-direction: column-reverse;
	align-items: flex-end;
}
.AgentsWeightsInputsModal {
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.PlaceSearchBox .mainTableContainer {
	overflow: auto;
	transition: max-height 406ms ease-in-out, height 406ms ease-in-out;
}
.PlaceSearchBox .mainTableContainer .mainTable {
	width: 100%;
}
.PlaceSearchBox .mainTable td,
.PlaceSearchBox .mainTable th {
	padding-left: 10px;
	padding-top: 3px;
	padding-bottom: 3px;
}
.PlaceSearchBox .mainTable th {
	font-weight: bold;
}
.PlaceSearchBox .mainTable tbody tr:hover {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	background-color: #ffffff88;
}

.MaxSharingSlider .Slider {
	margin-top: 10px;
}
.MaxSharingSlider .Label {
	display: flex;
	align-items: center;
}
.MaxSharingSlider .LabelText {
	padding-top: 2px;
}

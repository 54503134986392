.Guideline {
	position: relative;
	display: grid;
	text-align: center;
	grid-template-rows: 35px min-content;
}
.Guideline .IsCarryArrow {
	position: absolute;
	left: -25px;
	color: #b1b1b1;
	font-size: 21px;
}
.Guideline .MonthName {
	align-self: end;
	line-height: 92%;
	padding-bottom: 13px;
}
.Guideline .Amount {
	min-width: 100px;
	max-width: 150px;
}
.Guideline .Spent {
	line-height: 1;
	margin-top: 10px;
	font-size: 13px;
	color: #939393;
}
.Guideline .Spent .ActualSpent {
	font-size: 16px;
	font-weight: 600;
}

.LeadsDataTable tbody {
	font-size: 12px;
}
.OpenWebLeadButtonContent {
	color: white;
}
.WebLeadsModule .divider {
	margin-bottom: 10px;
	display: grid;
	grid-template-columns: min-content 1fr;
}
.WebLeadsModule .divider .CSVButton {
	margin-top: auto;
}
.bx--btn--primary {
	background-color: #00addb;
}

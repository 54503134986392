.nav > li > a {
	background-color: #fcfcfc;
	border: 1px solid #eee;
}
.nav > li > a:hover {
	-webkit-transition: all 300ms ease-in;
	-o-transition: all 300ms ease-in;
	transition: all 300ms ease-in;
	background-color: #999;
	border: 1px solid lightgrey;
}
.nav > li > a:focus i,
.nav > li > a:hover i {
	-webkit-transition: all 300ms ease-in;
	-o-transition: all 300ms ease-in;
	transition: all 300ms ease-in;
	color: #333;
}

.nav li.active ul li a {
	background-color: #fcfcfc;
	border: 1px solid #eee;
}
.sidebar-wrapper {
	overflow-x: hidden !important;
}
@media screen and (max-width: 991px) {
	.sidebar .logo {
		background-color: white;
		textalign: "center";
	}
	.sidebar .logo img {
		max-height: 70px;
		min-height: 70px;
	}
	.sidebar .account-selector {
		max-height: 50px;
		min-height: 50px;
	}
	.sidebar::before {
		background-color: white;
	}
}
.sidebar .nav .nav {
	margin-top: 10px;
}
.sidebar .account-selector:after {
	z-index: 3800;
	content: "";
	position: absolute;
	right: 10%;
	height: 1px;
	width: 80%;
	background-color: rgba(180, 180, 180, 0.3);
}
.sidebar .react-bootstrap-table-pagination {
	display: none;
}
.sidebar .sidebar-wrapper {
	height: calc(100vh - 154px) !important;
}
.menu_closed .sidebar .sidebar-wrapper {
	height: calc(100vh - 170px) !important;
}
.sidebar-wrapper::-webkit-scrollbar {
	width: 0px;
	background-color: #fff;
}

.AgentsTable {
	width: 100%;
}
.AgentsTable .Active {
	color:green
}
.AgentsTable .Inactive {
	color:red
}
.AgentMoveModal {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}
.NameContainer {
	display: flex;
	align-items: center;
}
.AgentIcon {
	height: 25px;
	width: 25px;
	margin-right: 10px;
}
.AgentText {
	padding-top: 2px;
}
.AlertAndNameContainer {
	display: flex;
	height: 100%;
}
.AgentAlert {
	margin: -16px 4px -16px -16px;
	width: 12px;
}
.AgentsTable .MuiTable-root {
	height: 100%;
}
.AgentsTable .MuiTableRow-root {
	height: 100%;
}
.AgentsTable .MuiTableCell-root {
	height: 100%;
}

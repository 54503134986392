.KeywordsInput .Buttons {
	display: flex;
	justify-content: flex-end;
}
.KeywordsInput button {
	margin-right: 10px;
}
.KeywordsInput {
	margin: 20px;
}
.KeywordsInput textarea {
	line-height: 1.5;
}
.KeywordsInput .Tip {
	color: rgb(32, 31, 31);
	font-size: 0.9rem;
	font-style: italic;
}

.ProxyGroupsList .ProxyGroupDisplay .ProxyGroup {
	padding: 0px 15px;
}
.ProxyGroupsList .ProxyGroupDisplay .Separator {
	height: 75px;
	display: grid;
}
.ProxyGroupsList .ProxyGroupDisplay .Separator hr {
	border-top-color: #dadada;
	align-self: center;
	width: 100%;
}

.CreateProxyParams {
	width: 100%;
	height: 100%;
}
.CreateProxyParams .CreateProxyContent .SelectProxyGroup {
	padding: 10px;
}
.CreateProxyParams .CreateProxyContent .Name {
	background-color: white;
	width: 100%;
	margin-top: 40px;
	margin-bottom: 40px;
}
.CreateProxyParams .CreateProxyContent .MaxSharing {
	margin-bottom: 60px;
	width: 100%;
}
.CreateProxyParams .CreateProxyContent .BoostConfig {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.CreateProxyParams .CreateProxyContent .BoostConfig div {
	display: flex;
	align-items: center;
}

.LanguagePreferenceChooser {
	display: flex;
	margin-top: 20px;
}
.LanguagePreferenceChooser .Container {
	height: 75px;
	width: 350px;
}
.LanguagePreferenceChooser .Loader {
	width: 75px;
}
.LanguagePreferenceChooser .MuiInputBase-root {
	width: 100%;
}

.MinimalistButton {
	cursor: pointer;
	font-size: 20px;
	margin: 15px;
	padding: 20px;
	box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.2);
	border: 1px solid #ddd;
	border-bottom: none;
}
.MinimalistButton .Subtitle {
	font-size: 14px;
	margin-top: 8px;
}
.MinimalistButton .Alert {
	float:left;
	width: 12px;
	height: calc(100% + 40px);
	margin: -20px 0px 0px -20px;
}

.AudienceAdsSelection {
	width: 600px;
	padding: 20px;
}
.AudienceAdsSelection hr {
	margin: 10px 0 10px 0;
}
.AudienceAdsSelection .Selects .SelectAdGroup {
	margin-right: 20px;
}
.AudienceAdsSelection .AdCopiesCard {
	max-height: 500px;
	overflow: auto;
}
.AudienceAdsSelection .AdCopiesCard .AdCopyCard {
	padding: 20px;
	margin: 20px;
}
.AudienceAdsSelection .Buttons {
	display: flex;
	justify-content: flex-end;
}

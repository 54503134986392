.BudgetPlanner .MainBudgetsContent .BudgetPlannerTable {
	font-size: 14px;
	font-family: arial;
	table-layout: fixed;
	border-collapse: collapse;
	width: 100%;
}
.BudgetPlanner .MainBudgetsContent .BudgetPlannerTable thead tr th {
	font-weight: bold;
}
.BudgetPlanner .MainBudgetsContent .BudgetPlannerTable tr .CurrentMonth {
	background-color: #d6f1f9;
}
.BudgetPlanner
	.MainBudgetsContent
	.BudgetPlannerTable
	.BudgetPlannerTable
	tbody
	tr
	.BudgetName {
	font-weight: bold;
	font-size: 12px;
}
.BudgetPlanner .MainBudgetsContent .PlannedBugetInput {
	width: 100%;
	background: snow;
}
.BudgetPlanner .MainBudgetsContent .TotalByBudget,
.TotalByMonth,
.Sum {
	font-weight: bold;
}
@media screen and (max-width: 900px) {
	.BudgetPlanner
		.MainBudgetsContent
		.BudgetPlannerTable
		tbody
		tr
		.BudgetName {
		white-space: normal;
		font-size: 12px;
	}
}
.BudgetPlanner .MainBudgetsContent {
	display: grid;
	grid-auto-flow: row;
}
.BudgetPlanner .MainBudgetsContent .previous,
.MainBudgetsContent .next {
	display: grid;
	width: 5px;
	margin-left: auto;
	margin-right: auto;
}
.BudgetPlanner .MainBudgetsContent .NavigationButtons {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	align-self: center;
}
.BudgetPlanner .MainBudgetsContent .amount {
	text-align: right;
	white-space: nowrap;
}
.BudgetPlanner .MainBudgetsContent .BudgetPlannerTable td > div {
	margin: 0px 5px;
}
.BudgetPlanner .MainBudgetsContent .BudgetPlannerTable div > input {
	width: 100%;
	height: 20px;
	text-align: right;
}
.BudgetPlanner .ReadOnlyConfirmationModal .bx--modal__buttons-container:after {
	content: "Read only";
	position: absolute;
	top: 241px;
	right: 20px;
	width: auto;
	height: 34px;
	padding-left: 5px;
	padding-right: 5px;
	background: #ffdb4c;
	color: black;
	opacity: 1;
	font-weight: bold;
	transform: rotate(30deg);
	font-size: 11px;
	line-height: 34px;
	font-family: ibm-plex-sans, "Helvetica Neue", Arial, sans-serif;
	box-shadow: rgba(0, 0, 0, 0.3) 4px 6px 4px;
}
.BudgetPlanner .viewChangesBtn {
	float: right;
	position: absolute;
	right: 10px;
	top: 20px;
}

.AudienceAdsListContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	height: 500px;
	width: 600px;
	padding: 20px;
}
.AudienceAdsListContainer .SearchInput {
}
.AudienceAdsListContainer .AudienceAdsList {
	height: 400px;
	width: 600px;
}

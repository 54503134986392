.ProxyGroupsPlanningChart {
	height: 700px;
}
.ProxyGroupsPlanningChart .XTicks .BaseText {
	font-size: 25px;
	fill: #9b9b9b;
}
.ProxyGroupsPlanningChart .XTicks.CurrentMonth .BaseText {
	font-size: 40px;
	font-weight: bold;
	fill: #02b2e3;
}
.ProxyGroupsPlanningChart .XTicks.CurrentMonth .ExtraText {
	font-size: 20px;
	fill: #9b9b9b;
}
.ProxyGroupsPlanningChart svg:not(:root) {
	overflow: visible;
}

.ProxyGroupParams {
	padding: 5px 0px 15px 0px;
	display: flex;
	justify-content: space-between;
}
.ProxyGroupParams .FirstColumn {
	display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.ProxyGroupParams .Buttons {
	display: flex;
	flex-direction: column-reverse;
	margin-left: auto;
	justify-content: flex-start;
}
.ProxyGroupParams .EditChannel {
	padding: 0px 20px;
	min-width: 200px;
}
.ProxyGroupParams .Sharing {
	display: flex;
	align-items: center;
	font-weight: bold;
}
.ProxyGroupParams .Sharing .ShareIcon {
	margin-right: 5px;
}

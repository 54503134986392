.MapEditor {
	margin-top: -50px;
}
.MapEditor .Info {
	display: flex;
	justify-content: flex-end;
	margin-right: 17px;
}
.MapEditor .NoRadius {
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 50%;
	margin: auto;
}
.MapEditor .Errors {
	color: #e53935;
	padding: 20px;
	margin-bottom: -40px;
}
.MapEditor .RadiusManagerDiv {
	overflow: auto;
	max-height: 400px;
}
.MapEditor .RadiusManagerDiv,
.PlaceEditor {
	margin: 10px;
}

.IdentitySetup .Identity {
	display: grid;
	grid-row-gap: 15px;
	font-weight: bold;
}
.IdentitySetup .Identity input {
	margin-right: 20px;
}
.IdentitySetup .Identity .TitleAndCampaignGroup {
	margin-bottom: 20px;
	display: flex;
	gap: 60px;
}

.IdentitySetup .IdentitySectionTitle {
	font: normal normal normal 22px/29px Roboto, sans-serif;
	margin-bottom: 30px;
}
.IdentitySetup .Input{
	width: 300px;
}
.IdentitySetup .DatePickers{
	display: flex;
	gap: 60px;
} 

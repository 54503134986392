.StatusModalContent {
	display: grid;
	grid-template-columns: 1fr;
	grid-row-gap: 10px;
}
.ReadOnlyStatusExceptionButton:after {
	content: "Read only";
	position: absolute;
	top: -20px;
	right: -25px;
	width: auto;
	height: 34px;
	padding-left: 5px;
	padding-right: 5px;
	background: #ffdb4c;
	color: black;
	opacity: 1;
	font-weight: bold;
	transform: rotate(30deg);
	font-size: 11px;
	line-height: 34px;
	font-family: ibm-plex-sans, "Helvetica Neue", Arial, sans-serif;
	box-shadow: rgba(0, 0, 0, 0.3) 4px 6px 4px;
}
.ReadOnlyStatusExceptionButton .StatusExceptionButton {
	width: 100%;
}

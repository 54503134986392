.KPI {
	display: grid;
	grid-template-columns: 1fr 3fr;
	grid-column-gap: 10px;
}
.KPI .number {
	align-self: center;
	text-align: center;
	font-size: 48px;
	padding: 10px;
	border-right: #ddd solid 1px;
}
.KPI .text {
	align-self: center;
	text-align: left;
}
.KPI .text .title {
	font-size: 14px;
	font-weight: bold;
	color: #00b1e4;
}
.KPI .text .subtitle {
	font-size: 12px;
	color: darkgrey;
}
@media screen and (max-width: 1400px) {
	.KPI {
		grid-template-columns: 1fr;
	}
	.KPI .number {
		border-right: none;
	}
	.KPI .text {
		text-align: center;
	}
}

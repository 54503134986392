.DisabledPageOverlay {
	position: absolute;
	width: 101%;
	height: 100vh;
	background-color: #000000aa;
	top: 0px;
	left: 0px;
	z-index: 1;
	color: white;
	text-align: center;
	padding-top: 350px;
	font-size: 30px;
}
.DisabledPageOverlay .DismissOverlayButton {
	font-size: 15px;
	text-decoration: underline;
	cursor: pointer;
}
.DisabledPageOverlay .DismissOverlayButton:hover {
	color: #00b2e3;
}

.GoBackLarge {
	visibility: hidden;
}

.GoBackLarge::after {
	visibility: visible;
	content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABHNCSVQICAgIfAhkiAAAAmVJREFUaEPtmr9Lw0AUxy8dHNRdN9HBoVihUvEHKC7FQksLpQUVR0GdtJP/goKgm04iIoibg4N2EqtVBB0EBRdFF3WTolBc6veVRmJIrSl557Uk8GhyeXfv+7l3l+bHaaLMVigUIjjVB+spWWs5X+byZ7R/VbILTdP2reJp5kIAtKNsGzbILLDa5rOoOAmgB2MDP0AAMYWTK7DmaqNIqveOOHOA2dDjfYMAYgaFa5KEOBVmFjDr1FgRBBAd+LmGNTkVQVI7H4jTDZh7DRAEc6LwnKjUJ6dwGCKQfuycVfJW/PwAgcyXJrjiWn+VlyKQPbjEapkC2ncJ5AU7LTUO8kgghRqHKMp3QVTLopsRNyNMPeAOLaaOrbpZKRnJZrMil8uJUChUtdBKFdlBCCKdThd1BAIBEYnQE7TzGyuIEYKk+/1+EY1GBZ4fHCdhAzFD+Hw+EY/HWSDYblFkQ7CAmCG8Xq9IJpNsmdDHqKNDywoikUgIj8fj+JwwN+iClOviuhhaOlxdTPb/gnF0jpiHm9UfYizG856DFYTAzLco4XCY5VLMDkIwmUxG5PN5EQwG2S7DUkDY1BsadkFk9LKdGG5G7PSWDF83IzJ62U6MuspIPXxWeKWMHCCFo3bSqKDvIYEsQtiCguLsSFoikDHU2LFTS0HfcQJphLBzmE9BgX+RdAOnXn3BQBcOLmENf6mpkM8ntPjxwu/WuISjFj9TpwCxSh1rXlQzjbJlmOqLat6gkRbVbOmjw2qZUxtObsJGFBpCRilHOJgABK3j+t7Kvk3GRaATXsMGI8D/2J4Q9Fg3ANxZifgClE44Zv2gYZgAAAAASUVORK5CYII=);
	position: absolute;
	top: 15px;
	left: 10px;
	color: #7F7F7F;
}
.Tag {
	border-radius: 5px;
	font-weight: bold;
	font-style: italic;
	color: #00b2e3;
}
.Tag-Dragging {
	color: white;
	border-radius: 10px;
}
